import { createRouter, createWebHistory } from 'vue-router'
//登录注册
import Login from '@/views/login/Login.vue'
import Register from '@/views/login/Register.vue'
//导出
const routes = [
  {
    path: '/layout',
    name: 'layout', // 路由名称
    component: () => import(/*webpackChunkName:"name"*/'@/views/Layout'),
    redirect: '/layout/homepage',
    children: [
      //首页
      {
        path: 'homepage',
        name: 'homepage',
        component: () => import(/*webpackChunkName:"name"*/'@/views/main/homepage')
      },
      {
        path: 'homePicture',
        name: 'homePicture',
        component: () => import(/*webpackChunkName:"name"*/'@/views/main/homePicture')
      },
      //公司荣耀
      {
        path: 'compGlory',
        name: 'compGlory',
        component: () => import(/*webpackChunkName:"name"*/'@/views/main/compGlory')
      },
      {
        path: 'compProfile',
        name: 'compProfile',
        component: () => import(/*webpackChunkName:"name"*/'@/views/main/compProfile')
      },
      {
        path: 'contactWay',
        name: 'contactWay',
        component: () => import(/*webpackChunkName:"name"*/'@/views/main/contactWay')
      },
      {
        path: 'foreAgent',
        name: 'foreAgent',
        component: () => import(/*webpackChunkName:"name"*/'@/views/main/foreAgent')
      },

      {
        path: 'legalSupport',
        name: 'legalSupport',
        component: () => import(/*webpackChunkName:"name"*/'@/views/main/legalSupport')
      },
      {
        path: 'newsTrends',
        name: 'newsTrends',
        component: () => import(/*webpackChunkName:"name"*/'@/views/main/newsTrends')
      },
      {
        path: 'patentAgent',
        name: 'patentAgent',
        component: () => import(/*webpackChunkName:"name"*/'@/views/main/patentAgent')
      },
      {
        path: 'servScope',
        name: 'servScope',
        component: () => import(/*webpackChunkName:"name"*/'@/views/main/servScope')
      },
      {
        path: 'pageFoot',
        name: 'pageFoot',
        component: () => import(/*webpackChunkName:"name"*/'@/views/main/pageFoot')
      },
      {
        path: 'contactWayList',
        name: 'contactWayList',
        component: () => import(/*webpackChunkName:"name"*/'@/views/main/dialog/contactWayList')
      },
      {
        path: 'contactWayEdit',
        name: 'contactWayEdit',
        component: () => import(/*webpackChunkName:"name"*/'@/views/main/dialog/contactWayEdit')
      },
      {
        path: 'proAgent',
        name: 'proAgent',
        component: () => import(/*webpackChunkName:"name"*/'@/views/main/patentagent/proAgent')
      },
      {
        path: 'execDirector',
        name: 'execDirector',
        component: () => import(/*webpackChunkName:"name"*/'@/views/main/patentagent/execDirector')
      },
      {
        path: 'servscopeChild',
        name: 'servscopeChild',
        component: () => import(/*webpackChunkName:"name"*/'@/views/main/servscopeChild')
      },
    ],
  },
  {
    path: "/", meta: [],
    name: 'Login',
    component: Login,
  },
  {
    path: "/Register",
    name: 'Register',
    component: Register,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition && to.meta.keepAlive) {
      return savedPosition;
    }
    return { x: 0, y: 0 };
  },
})

router.beforeEach((to, from, next) => {
  next()
})

export default router
